import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { Validators } from "@angular/forms";
import { GridComponent } from "../../shared/grid/grid.component";
import { Router } from "@angular/router";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { taskModalColumns } from "./task-modal.columns";
import { Claims } from "src/app/auth/models/claims";
import {debounceTime, take} from "rxjs/operators";
import {ViewCategories} from "../../shared/enums/view-categories.enum";

@Component({
  templateUrl: "./task-modal.component.html"
})
export class TaskModalComponent
  extends BaseModalComponent
{
  @Output()
  onSave = new EventEmitter();
  task = <any>{};
  hasClaim = false;
  isAdhoc = false;
  issue = <any>{};
  isNew = false;
  allNames = ["Tasks Photos"];
  taskModalColumns = taskModalColumns;

  addTaskRecordButtons = [];
  getNav = () => [
    { name: "Task Details", page: "details", disabled: false },
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    { name: "Task Records", page: "taskRecords", disabled: false },
    { name: "Map", page: "map" },
    {
      name: "Issue",
      page: "issue",
      hidden: this.isNew || (!this.isNew && !this.task.issueNumber)
    },
    { name: "Photos", page: "photos", disabled: false },
    { name: "Links & Files", page: "linksAndFiles", disabled: false },
    { name: "History", page: "auditTrail", disabled: false, hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private taskRecordGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TaskModalComponent>,
    private router: Router
  ) {
    super();
    this.isNew = !data.data.id;
    this.issue = data.data.issue;
    this.isAdhoc = data.data.isAdhoc;
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.task = data.data;

    this.remoteService.getFields(ViewCategories.Tasks)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];

        this.task.parsedLatitude =
          this.task.latitude !== null ? parseFloat(this.task.latitude) : null;
        this.task.parsedLongitude =
          this.task.longitude !== null ? parseFloat(this.task.longitude) : null;

        if (this.task.taskStatus != "Adhoc Completed") {
          this.addTaskRecordButtons = this.authService.hasClaim(
            Claims.taskEdit
          ) && [{ name: "Add Task Record", action: this.addTaskRecord }];
        }

        if (this.issue && this.issue.site) {
          this.task.site = this.issue.site;
        }

        if (this.isNew) {
          if (this.task.program) {
            this.task.programs = [this.task.program];
          }
          this.addTaskRecordButtons = [];
        }

        this.createForm(this.fields, this.task).then((form) => {
          this.form = form;
          this.form.controls["what3Words"].disable();

          if (this.isNew) {
            if (this.issue || this.isAdhoc) {
              this.form.controls["taskCategory"].setValue(
                this.metaData.taskCategories.find((f) => f.name == "Adhoc Work")
              );
              this.form.controls["taskType"].setValue(
                this.metaData.taskTypes.find((f) => f.name.includes("Adhoc"))
              );
            }

            if (this.issue) {
              this.form.controls["description"]
                .setValue(`Created for the Resolution of
            Issue (${this.issue.issueNumber}) : ${
                  this.issue.issueCategoryName
                } - ${this.issue.issueTypeName}\n ${
                  this.issue.description ? this.issue.description : ""
                }`);
              this.task.images = this.issue.images;

              this.form.controls["latitude"]
                .setValue(this.issue.latitude);
              this.form.controls["longitude"]
                .setValue(this.issue.longitude);
            }


          }
          if (this.task.taskStatus == "Awaiting Review") {
            this.form.controls["programs"].disable();

            this.form.controls["updateStatus"].setValidators(Validators.required);
            this.form.controls["updateStatus"].setValue({
              id: 3,
              name: "Awaiting Review"
            });

            this.form.controls["updateStatus"].valueChanges.subscribe((v) => {
              if (v.id == 3) {
                this.form.controls["programs"].disable();
              } else {
                this.form.controls["programs"].enable();
              }
            });
          }

          this.form.controls["taskCategory"].valueChanges.subscribe((val) => {
            if (val && val.name) {
              if (val.name === "Adhoc Work") {
                this.form.controls["taskPriority"].setValidators(
                  Validators.required
                );
              } else {
                this.form.controls["taskPriority"].removeValidators(
                  Validators.required
                );
                this.form.controls["taskPriority"].reset();
              }
            }
          });

          this.form.controls["measuredDistances"].disable();

          this.form.controls["latitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.task.parsedLatitude = parseFloat(v);
            })

          this.form.controls["longitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.task.parsedLongitude = parseFloat(v);
            })

          this.form.controls["issueNumber"].disable();
          this.form.controls["issueCategoryName"].disable();
          this.form.controls["issueTypeName"].disable();
          this.form.controls["siteName"].disable();
          this.form.controls["issueDescription"].disable();
          this.form.controls["issueLatitude"].disable();
          this.form.controls["issueLongitude"].disable();
          this.form.controls["riskName"].disable();
          this.form.controls["escalated"].disable();
          this.form.controls["isClosed"].disable();

          if (this.task.site && this.task.site.id) {
            this.form.controls["site"].disable();
          }
          if (this.issue || this.isAdhoc) {
            setTimeout(() => {
              this.form.controls["taskCategory"].disable();
              this.form.controls["taskType"].disable();
            }, 100);

            this.form.controls["programs"].setValidators(Validators.required);
            this.form.controls["taskPriority"].setValidators(Validators.required);
          }
          this.loaded = true;
          setTimeout(() => this.form.markAllAsTouched(), 5000);
          //Check Claims
          !this.authService.hasClaim(Claims.taskEdit)
            ? this.form.disable()
            : (this.hasClaim = true);
        });
      });


  }

  changeSection(item) {
    if (item.page === "auditTrail" || item.page === "taskRecords") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.task[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`task/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.task[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  trackImage($event) {
    this.task.images.push({
      id: $event
    });
  }

  save() {
    if (!this.saving) {
      this.saving = true;
      this.task = {...this.task, ...this.form.getRawValue()};
      if (this.issue) {
        this.task.issue = {id: this.issue.id};
      }
      this.onSave.emit(this.task);
      setTimeout(() => this.saving = false, 50000);
    }
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  checkImages($event) {
    if (!this.task.images) {
      this.task.images = [];
    }
    this.task.images.push($event);

    this.form.markAsDirty();
  }

  checkDocuments($event) {
    if (!this.task.documents) {
      this.task.documents = [];
    }
    this.task.documents.push($event);
    this.form.markAsDirty();
  }

  addTaskRecord(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.TaskRecord,
        afterSave: context.saveTaskRecord,
        payload: { task: { id: context.task.id }, taskName: context.task.name }
      })
    );
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Task Record until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.TaskRecord,
        autoSave: false,
        afterSave: this.saveTaskRecord
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveTaskRecord(context, entity, dialogRef) {
    if (entity.taskRecordStatus.name) {
      entity.taskRecordStatusName = entity.taskRecordStatus.name;
    }
    if (entity.program.name) {
      entity.programName = entity.program.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    context.addOrReplace(context.task.taskRecords, entity);
    // If the user hasn't viewed the TaskRecord Table yet...
    if (context.taskRecordGridComponent) {
      context.taskRecordGridComponent.grid.data = [...context.task.taskRecords];
    }
    dialogRef.close();
    context.form.controls["name"].markAsDirty();
    context.form.markAsDirty();
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() => {
      if (this.task.site) {
        this.router.navigate([
          `/map/taskId/${this.task.id}/${this.task.site.id}`
        ]);
      } else {
        this.router.navigate([`/map/taskId/${this.task.id}`]);
      }
    });
  }
}
