import { IgxFilteringOperand } from "igniteui-angular";

export class CustomNumberFilteringOperand extends IgxFilteringOperand {
  public constructor() {
    super();
    this.operations = [
      {
        name: "equals",
        isUnary: false,
        iconName: "equals",
        logic: (target: number, searchVal: number) => {
          return target == searchVal;
        }
      }, {
        name: "doesNotEqual",
        isUnary: false,
        iconName: "not-equal",
        logic: (target: number, searchVal: number) => {
          return target != searchVal;
        }
      }, {
        name: "greaterThanOrEqualTo",
        isUnary: false,
        iconName: "greater-than-or-equal",
        logic: (target: number, searchVal: number) => {
          return target >= searchVal;
        }
      }, {
        name: "lessThanOrEqualTo",
        isUnary: false,
        iconName: "less-than-or-equal",
        logic: (target: number, searchVal: number) => {
          return target <= searchVal;
        }
      }
    ].concat(this.operations);
  }
}
