import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, enableProdMode, Injector, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { NoopAnimationsModule, BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./auth/auth.service";
import { LoginComponent } from "./auth/login/login.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { SharedModule } from "./shared/modules/shared.module";
import { BaseComponent } from "./shared/base/base.component";
import { IgxCsvExporterService, IgxExcelExporterService, IgxIconModule } from "igniteui-angular";
import { InjectorService } from "./shared/services/injector/injector.service";
import { BaseModalComponent } from "./shared/base/base-modal.component";
import { AppConfigurationService } from "./shared/services/app-config/app-configuration.service";
import { LoginDatabaseComponent } from "./auth/login-database/login-database.component";
import { ImageService } from "./shared/services/image/image.service";
import { MatDialogRef } from "@angular/material/dialog";
import { HubComponent } from "./shared/hub/hub.component";
import { HomeComponent } from "./home/home.component";
import { ModalsModule } from "./modals/modals.module";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  return new PublicClientApplication({
    auth: {
      clientId: '050f76d6-8dc2-4a2d-9c31-a6992de3f3cd',
      authority: 'https://login.microsoftonline.com/9d465962-439d-4c3a-b1c8-ce823a5d218f',
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // Configure your protected resource endpoints. For each endpoint, you need to list the scopes that will be used with it.
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}


export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

const appInitializerFn = (appConfig: AppConfigurationService) => {
  return () => appConfig.loadConfig();
};

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ResetPasswordComponent,
    HubComponent,
    BaseComponent,
    BaseModalComponent,
    LoginDatabaseComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ModalsModule,
    IgxIconModule,
    MsalModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigurationService]
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    AuthService,
    ImageService,
    AppConfigurationService,
    IgxExcelExporterService,
    IgxCsvExporterService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorService.setInjector(injector);
  }
}
