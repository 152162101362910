import { IgxNumberFilteringOperand } from "igniteui-angular";
import { CustomNumberFilteringOperand } from "../custom-filter-operands/custom-number-filters";

export const NumberFilterOperations =
[
  {
    name: "Equals",
    iconName: "equals",
    logic: CustomNumberFilteringOperand.instance().condition("equals")
  }, {
    name: "Does Not Equal",
    iconName: "not-equal",
    logic: CustomNumberFilteringOperand.instance().condition("doesNotEqual")
  }, {
    name: "Greater Than",
    iconName: "greater-than",
    logic: IgxNumberFilteringOperand.instance().condition("greaterThan")
  }, {
    name: "Less Than",
    iconName: "less-than",
    logic: IgxNumberFilteringOperand.instance().condition("lessThan")
  }, {
    name: "Greater Than Or Equal To",
    iconName: "greater-than-or-equal",
    logic: CustomNumberFilteringOperand.instance().condition("greaterThanOrEqualTo")
  }, {
    name: "Less Than Or Equal To",
    iconName: "less-than-or-equal",
    logic: CustomNumberFilteringOperand.instance().condition("lessThanOrEqualTo")
  }, {
    name: "Empty",
    iconName: "is-empty",
    logic: IgxNumberFilteringOperand.instance().condition("empty")
  }, {
    name: "Empty",
    iconName: "not-empty",
    logic: IgxNumberFilteringOperand.instance().condition("notEmpty")
  }
];
