import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";
import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

@Component({
  selector: 'app-new-inspection-modal',
  templateUrl: './new-inspection-modal.component.html',
  styleUrls: ['./new-inspection-modal.component.css']
})
export class NewInspectionModalComponent extends BaseModalComponent {

  title = 'Record an Inspection'
  page = 'start';
  inspection = <any>{};

  failedChecks = [];
  passedChecks = [];

  inComplete: boolean = true;

  fields: IEditFormField[] = [
    {name: "site", label: "Site", type: FieldType.Select},
    {name: "equipment", label: "Equipment", type: FieldType.Select},
    {name: "inspection", label: "Inspection", type: FieldType.Select},
    {name: "mobileCreatedDate", label: "Recorded Date", type: FieldType.Date},
    {name: "mobileCreatedTime", label: "Recorded Time", type: FieldType.Date}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
  ) {
    super();
    this.metaData = data.metaData;
    this.inspection = data.data;

    this.inspection.mobileCreated = this.newIsoDate();
    this.inspection.mobileCreatedDate = this.inspection.mobileCreated.split("T")[0];
    this.inspection.mobileCreatedTime = this.inspection.mobileCreated.match(/([A-Z]?[^A-Z]*)/g)[1]
      .slice(1, -1).slice(0, 5);

    this.createForm(this.fields, this.inspection).then((form) => {
      this.form = form;
      this.loaded = true;

      if (form.controls["site"].value) {
        this.remoteService.getBy("inspection", "for", form.controls["site"].value.id)
          .subscribe((response) => {
            if (response && response.length > 0) {
              this.metaData.inspections = response;
            } else {
              this.baseService.openSnackBar(`Site has no Inspections Associated. Please Add an Inspection
              to continue.`, null, true);
            }
          })
      }
      if (form.controls["equipment"].value) {
        this.remoteService.getBy("inspection", "for", form.controls["equipment"].value.id)
          .subscribe((response) => {
            if (response && response.length > 0) {
              this.metaData.inspections = response;
            } else {
              this.baseService.openSnackBar(`Equipment has no Inspections Associated. Please Add an Inspection
              to continue.`, null, true);
            }
          })
      }

      this.form.controls["site"].valueChanges.subscribe((v) => {
        if (v) {
          this.form.controls["equipment"].setValue(null, {emitEvent: false});
          this.remoteService.getBy("inspection", "for", v.id)
            .subscribe((response) => {
              this.metaData.inspections = response;
            })
        }
      });
      this.form.controls["equipment"].valueChanges.subscribe((v) => {
        if (v) {
          this.form.controls["site"].setValue(null, {emitEvent: false});
          this.remoteService.getBy("inspection", "for", v.id)
            .subscribe((response) => {
              this.metaData.inspections = response;
            })
        }
      });
    });
  }

  startInspection() {
    this.page = 'inspection';
    this.title = this.form.controls["equipment"].value
      ? `${this.form.controls["equipment"].value.name} - ${this.form.controls["inspection"].value.name}`
      : `${this.form.controls["site"].value.name} - ${this.form.controls["inspection"].value.name}`
    this.loaded = false;
    this.remoteService.getBy("inspection", "checks",
      this.form.controls["inspection"].value.id)
      .subscribe((checks) => {
        this.inspection.checks = checks
        this.loaded = true;
      });
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  inspectionComplete(checks) {
    this.prepChecks(checks).then(() => {
      // If there are no failed Checks then we can just save and move on
      if (!checks.find(f => f.inspectionCheckItemResult.id == 1)) {
        this.save(checks);
      } else {
        // Otherwise we need to ascertain what Risk is applied to each failed check
        let inspection = this.metaData.inspections.find(f => f.id == this.form.controls["inspection"].value.id);
        if (inspection.riskRequired) {
          this.failedChecks = checks.filter(f => f.inspectionCheckItemResult.id == 1);
          this.passedChecks = checks.filter(f => f.inspectionCheckItemResult.id != 1);
          this.page = "failedChecks";
        } else {
          this.save(checks);
        }
      }
    });
  }

  private save(checks) {
    this.inspection = {...this.inspection, ...this.form.getRawValue()};
    this.inspection.mobileCreated = this.inspection.mobileCreatedDate
      ? this.formDateTime(
        this.inspection.mobileCreatedDate,
        this.inspection.mobileCreatedTime
      )
      : null;

    this.remoteService.add("inspectionRecord", null, {
      inspection: {id: this.form.controls["inspection"].value.id},
      inspectionStatus: {id: 0},
      inspectionCheckRecords: checks,
      created: this.inspection.mobileCreated
    }).subscribe(() => {
      this.baseService.openSnackBar("Inspection Recorded Successfully");
      this.dialogRef.close();
    }, ((error) => {
      this.baseService.openSnackBar(`Error - Unable to save Inspection Record (${error.message})`,
        null, true);
    }))
  }

  private prepChecks(checks) {
    return new Promise<void>((resolve) => {
      for (let i = 0; i < checks.length; i++) {
        checks[i].inspectionCheckItemResult = checks[i].grade;
        checks[i].inspectionCheckItemId = checks[i].id;
        delete checks[i].id;
      }
      resolve();
    })
  }

  riskFinished() {
    setTimeout(() => {
      for (let i = 0; i < this.failedChecks.length; i++) {
        if (!this.failedChecks[i].risk) {
          return;
        }
      }
      this.inComplete = false;
    }, 100);
  }

  saveAfterRisk() {
    const checks = [...this.failedChecks, ...this.passedChecks];
    this.save(checks);
  }

}
